import React, { Component } from 'react';
import { Button, Filter, TextInput } from 'react-admin';
import { withStyles } from '@material-ui/core';

const filterStyles = {
  filters: {
    display: 'flex',
  },
  filterBox: {},
  noPhotosButton: {},
};

class LocationFilter extends Component {
  constructor(props) {
    super(props);
    this.state = { buttonColor: '#38a9b4' };
  }

  handleClick() {
    if (this.state.buttonColor === 'red') {
      this.setState({
        buttonColor: '#38a9b4',
      });
    } else {
      this.setState({
        buttonColor: 'red',
      });
    }
  }

  render() {
    const { classes, loadingRecords, ...props } = this.props;

    return (
      <div className={classes.filters}>
        <Filter className={classes.filterBox} {...props}>
          <TextInput
            label="Search by state"
            source="state"
            alwaysOn
            resettable
            onChange={loadingRecords}
          />
          <TextInput
            label="Search by city"
            source="city"
            alwaysOn
            resettable
            onChange={loadingRecords}
          />
        </Filter>
        <Button
          className={classes.noPhotosButton}
          style={{ color: this.state.buttonColor }}
          onClick={() => {
            loadingRecords();
            this.handleClick();
            if (
              props.filterValues &&
              props.filterValues.photoCounter === 0
            ) {
              props.setFilters({
                ...props.filterValues,
                photoCounter: '',
              });
            } else {
              props.setFilters({
                ...props.filterValues,
                photoCounter: 0,
              });
            }
          }}
          label="Cities with no photos"
        />
      </div>
    );
  }
}

export default withStyles(filterStyles)(LocationFilter);
