import React from 'react';
import {
  Edit,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar,
} from 'react-admin';

import CancelButton from './../CancelButton';

const FacilityEditToolbar = ({ onCancel, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <CancelButton onCancel={onCancel} />
  </Toolbar>
);

const FacilityEditActions = () => <div></div>;

const FacilityEdit = ({ onCancel, ...props }) => (
  <Edit {...props} undoable={false} title=" " actions={<FacilityEditActions />}>
    <SimpleForm toolbar={<FacilityEditToolbar onCancel={onCancel} />}>
      <TextField source="name" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="web_page" />
      <TextField source="mobile_phone" />
      <ImageField source="imageUrl" label="Preview" />
      <ImageInput
        source="image"
        label="New Image"
        accept="image/jpeg"
        multiple={false}
        placeholder={<p>Upload image for this Facility</p>}
      >
        <ImageField source="src" label="Drop image" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export default FacilityEdit;
