import { CREATE } from 'react-admin';

import { API_URL } from './../../constants';

const mediaObjectsHandler = async (type, resource, params, requestHandler) => {
  switch (type) {
    case CREATE:
      if (params.data.image) {
        const image = params.data.image;
        if (!image.rawFile instanceof File) {
          return Promise.reject('Error: Not a file...');
        }

        const body = new FormData();
        body.append('file', image.rawFile);

        return fetch(`${API_URL}/media_objects`, {
          body,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        }).then((response) => {
          return Promise.resolve(response.json()).then((data) => ({
            data: {
              ...data,
              id: data['@id'],
            },
          }));
        });
      }

      return requestHandler(type, resource, params);
    default:
      return requestHandler(type, resource, params);
  }
};

export default mediaObjectsHandler;
