import { CREATE, DELETE_MANY, GET_LIST, GET_ONE, UPDATE } from 'react-admin';

import { API_RESOURCES, API_URL } from './../../constants';

const facilitiesHandler = async (type, resource, params, requestHandler) => {
  switch (type) {
    case GET_LIST:
      return requestHandler(type, resource, params).then(async (data) => {
        const resultData = {
          ...data,
          data: await Promise.all(
            data.data.map(async (x) => {
              let imageUrl = '';
              if (x.facilityImages.length > 0) {
                const firstImage = x.facilityImages[0];
                const facilityImageData = await requestHandler(
                  GET_ONE,
                  API_RESOURCES.FACILITY_IMAGES,
                  { id: firstImage },
                );
                const mediaObjectData = await requestHandler(
                  GET_ONE,
                  API_RESOURCES.MEDIA_OBJECTS,
                  { id: facilityImageData.data.image },
                );
                imageUrl = mediaObjectData.data.contentUrl;
              }

              x.imageUrl = imageUrl;

              return x;
            }),
          ),
        };
        return resultData;
      });
    case GET_ONE:
      const decodedParams = {
        ...params,
        id: decodeURIComponent(params.id),
      };
      return requestHandler(type, resource, decodedParams).then(
        async (data) => {
          let imageUrl = '';
          if (data.data.facilityImages.length > 0) {
            const firstImage = data.data.facilityImages[0];
            const facilityImageData = await requestHandler(
              GET_ONE,
              API_RESOURCES.FACILITY_IMAGES,
              { id: firstImage },
            );
            const mediaObjectData = await requestHandler(
              GET_ONE,
              API_RESOURCES.MEDIA_OBJECTS,
              { id: facilityImageData.data.image },
            );
            imageUrl = mediaObjectData.data.contentUrl;
          }

          data.data.imageUrl = imageUrl;

          return data;
        },
      );
    case UPDATE:
      if (params.data.image) {
        if (params.data.facilityImages.length > 0) {
          await requestHandler(DELETE_MANY, API_RESOURCES.FACILITY_IMAGES, {
            ids: params.data.facilityImages,
          });
        }

        const image = params.data.image;
        if (!image.rawFile instanceof File) {
          return Promise.reject('Error: Not a file...');
        }

        const body = new FormData();
        body.append('file', image.rawFile);

        const mediaObjectUpload = await fetch(`${API_URL}/media_objects`, {
          body,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
        const mediaObjectData = await mediaObjectUpload.json();

        const facilityImageData = {
          data: {
            facility: params.data['@id'],
            image: mediaObjectData['@id'],
          },
        };
        const facilityImages = await requestHandler(
          CREATE,
          API_RESOURCES.FACILITY_IMAGES,
          facilityImageData,
        );

        return {
          data: {
            ...params.data,
            imageUrl: mediaObjectData.contentUrl,
            facilityImages: [facilityImages.data.id],
          },
        };
      }

      return { data: params.data };
    default:
      return requestHandler(type, resource, params);
  }
};

export default facilitiesHandler;
