import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';

import StateCityAutoSuggest from './StateCityAutoSuggest';

const jobMatchesFormStyles = {
  formControl: {
    margin: 8,
    minWidth: 300,
  },
};

const JobMatchesForm = ({ classes, ...props }) => (
  <form onSubmit={props.handleSubmit}>
    <div>
      <FormControl className={classes.formControl}>
        <TextField
          label="Min net payment in cents"
          id="min_net_payment_field"
          type="number"
          value={props.minNetPayInCents}
          onChange={props.handleMinNetPayInCents}
        />
      </FormControl>
    </div>
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="best_assignment">Best Assignment</InputLabel>
        <Select
          id="best_assignment_select"
          value={props.bestAssignments}
          onChange={props.handleBestAssignments}
          multiple
        >
          {props.bestAssignmentOptions}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="main_interest">Main Interest</InputLabel>
        <Select
          id="main_interest_select"
          value={props.mainInterest}
          onChange={props.handleMainInterest}
        >
          {props.mainInterestOptions}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="shift">Shift</InputLabel>
        <Select
          id="shift_select"
          value={props.shift}
          onChange={props.handleShift}
        >
          {props.shiftOptions}
        </Select>
      </FormControl>
    </div>
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="start_working">Start Working</InputLabel>
        <Select
          id="start_working_select"
          value={props.startWorking}
          onChange={props.handleStartWorking}
        >
          {props.startWorkingOptions}
        </Select>
      </FormControl>
      <FormControl
        className={classes.formControl}
        disabled={props.specificStartDateDisabled}
      >
        <TextField
          id="date"
          type="date"
          label="Specific start date"
          defaultValue={props.specificStartDate}
          disabled={props.specificStartDateDisabled}
          onChange={props.handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
    </div>
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="working_place">Working Place</InputLabel>
        <Select
          id="working_place_select"
          value={props.workingPlaces}
          onChange={props.handleWorkingPlaces}
          multiple
        >
          {props.workingPlaceOptions}
        </Select>
      </FormControl>
    </div>
    <StateCityAutoSuggest
      handleAddingCityAndState={props.handleAddingCityAndState}
      handleDeleteCityAndState={props.handleDeleteCityAndState}
      userPreferredLocations={props.userPreferredLocations}
    />
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="specialities">Specialities</InputLabel>
        <Select
          id="specialities_select"
          value={props.specialties}
          onChange={props.handleSpecialties}
          multiple
        >
          {props.specialtiesOptions}
        </Select>
      </FormControl>
    </div>
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="care_settings">
          Care Settings (only 3 allowed)
        </InputLabel>
        <Select
          id="care_settings_select"
          value={props.careSettings}
          onChange={props.handleCareSettings}
          multiple
        >
          {props.careSettingsOptions}
        </Select>
      </FormControl>
    </div>
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="travel_goals">Travel Goals</InputLabel>
        <Select
          id="travel_goals_select"
          value={props.travelGoals}
          onChange={props.handleTravelGoals}
          multiple
        >
          {props.travelGoalOptions}
        </Select>
      </FormControl>
    </div>
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="magnet_hospital_importance">
          Magnet Hospital Importance
        </InputLabel>
        <Select
          id="magnet_hospital_importance_select"
          value={props.magnetHospitalImportance}
          onChange={props.handleMagnetHospitalImportance}
        >
          {props.magnetHospitalImportanceOptions}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="teaching_facility_importance">
          Teaching Facility Importance
        </InputLabel>
        <Select
          id="teaching_facility_importance_select"
          value={props.teachingFacilityImportance}
          onChange={props.handleTeachingFacilityImportance}
        >
          {props.teachingFacilityImportanceOptions}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="trauma_center_importance">
          Trauma Center Importance
        </InputLabel>
        <Select
          id="trauma_center_importance_select"
          value={props.traumaCenterImportance}
          onChange={props.handleTraumaCenterImportance}
        >
          {props.traumaCenterImportanceOptions}
        </Select>
      </FormControl>
    </div>
    <Button color="primary" type="submit">
      Submit
    </Button>
  </form>
);

export default withStyles(jobMatchesFormStyles)(JobMatchesForm);
