import React, { Component } from 'react';
import { MenuItem } from '@material-ui/core';
import { GET_LIST, Loading, showNotification } from 'react-admin';
import { connect } from 'react-redux';
import moment from 'moment';

import { apiDataProvider } from './../../App';
import { API_RESOURCES, JOB_MATCH_PREFERENCES } from './../../constants';
import JobMatchesForm from './JobMatchesForm';
import JobMatches from './JobMatches';

class FindJobMatchesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferenceOptions: [],
      bestAssignmentOptions: [],
      mainInterestOptions: [],
      careSettingsOptions: [],
      specialtiesOptions: [],
      specialtiesElements: [],
      magnetHospitalOptions: [],
      shiftOptions: [],
      startWorkingOptions: [],
      teachingFacilityOptions: [],
      traumaCenterOptions: [],
      travelGoalOptions: [],
      workingPlaceOptions: [],
      magnetHospitalImportanceOptions: [],
      teachingFacilityImportanceOptions: [],
      traumaCenterImportanceOptions: [],
      bestAssignments: [],
      mainInterest: '',
      shift: '',
      startWorking: '',
      magnetHospitalImportance: '',
      teachingFacilityImportance: '',
      traumaCenterImportance: '',
      specificStartDateDisabled: true,
      specificStartDate: moment(Date.now()).format('YYYY-MM-DD'),
      minNetPayInCents: 0,
      maxNetPayInCents: 0,
      workingPlaces: [],
      specialties: [],
      careSettings: [],
      travelGoals: [],
      loadingData: false,
      jobMatches: [],
      userPreferredLocations: [],
    };
  }

  componentDidMount() {
    const { showNotification } = this.props;

    apiDataProvider(GET_LIST, API_RESOURCES.SPECIALTIES, {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'name', order: 'ASC' },
      // filter: { name: JOB_MATCH_PREFERENCES.MAIN_INTEREST },
    })
      .then((response) => {
        const specialties = response.data;
        if (specialties.length > 0) {
          const mappedSpecialties = specialties.map((el) => (
            <MenuItem key={el.id} value={el.name}>
              {el.displayedName}
            </MenuItem>
          ));

          this.setState({
            specialtiesElements: specialties,
            specialtiesOptions: mappedSpecialties,
          });
        } else {
          showNotification('Error: specialties options are empty', 'warning');
        }
      })
      .catch((e) => {
        showNotification(
          `Error: could not fetch specialties . ${e.message}`,
          'warning',
        );
      });

    apiDataProvider(GET_LIST, API_RESOURCES.PREFERENCE_OPTIONS, {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'name', order: 'ASC' },
      filter: { name: JOB_MATCH_PREFERENCES.MAIN_INTEREST },
    })
      .then((response) => {
        const preferenceOptions = response.data;
        if (preferenceOptions.length > 0) {
          const mappedPreferenceOptions = preferenceOptions.map((el) => (
            <MenuItem key={el.name} value={el.name}>
              {el.displayedName}
            </MenuItem>
          ));

          const bestAssignmentOptions = mappedPreferenceOptions.filter((el) =>
            el.key.startsWith(JOB_MATCH_PREFERENCES.BEST_ASSIGNMENT),
          );
          const mainInterestOptions = mappedPreferenceOptions.filter((el) =>
            el.key.startsWith(JOB_MATCH_PREFERENCES.MAIN_INTEREST),
          );
          const careSettingsOptions = mappedPreferenceOptions.filter((el) =>
            el.key.startsWith(JOB_MATCH_PREFERENCES.CARE_SETTINGS),
          );
          const magnetHospitalOptions = mappedPreferenceOptions.filter((el) =>
            el.key.startsWith(JOB_MATCH_PREFERENCES.MAGNET_HOSPITAL),
          );
          const shiftOptions = mappedPreferenceOptions.filter((el) =>
            el.key.startsWith(JOB_MATCH_PREFERENCES.SHIFT),
          );
          const startWorkingOptions = mappedPreferenceOptions.filter((el) =>
            el.key.startsWith(JOB_MATCH_PREFERENCES.START_WORKING),
          );
          const teachingFacilityOptions = mappedPreferenceOptions.filter((el) =>
            el.key.startsWith(JOB_MATCH_PREFERENCES.TEACHING_FACILITY),
          );
          const traumaCenterOptions = mappedPreferenceOptions.filter((el) =>
            el.key.startsWith(JOB_MATCH_PREFERENCES.TRAUMA_CENTER),
          );
          const travelGoalOptions = mappedPreferenceOptions.filter((el) =>
            el.key.startsWith(JOB_MATCH_PREFERENCES.TRAVEL_GOAL),
          );
          const workingPlaceOptions = mappedPreferenceOptions.filter((el) =>
            el.key.startsWith(JOB_MATCH_PREFERENCES.WORKING_PLACE),
          );
          const magnetHospitalImportanceOptions = mappedPreferenceOptions.filter(
            (el) =>
              el.key.startsWith(
                JOB_MATCH_PREFERENCES.MAGNET_HOSPITAL_IMPORTANCE,
              ),
          );
          const teachingFacilityImportanceOptions = mappedPreferenceOptions.filter(
            (el) =>
              el.key.startsWith(
                JOB_MATCH_PREFERENCES.TEACHING_FACILITY_IMPORTANCE,
              ),
          );
          const traumaCenterImportanceOptions = mappedPreferenceOptions.filter(
            (el) =>
              el.key.startsWith(JOB_MATCH_PREFERENCES.TRAUMA_CENTER_IMPORTANCE),
          );

          this.setState({
            preferenceOptions,
            bestAssignmentOptions,
            mainInterestOptions,
            careSettingsOptions,
            magnetHospitalOptions,
            shiftOptions,
            startWorkingOptions,
            teachingFacilityOptions,
            traumaCenterOptions,
            travelGoalOptions,
            workingPlaceOptions,
            magnetHospitalImportanceOptions,
            teachingFacilityImportanceOptions,
            traumaCenterImportanceOptions,
            mainInterest: mainInterestOptions[0].key,
            shift: shiftOptions[0].key,
            startWorking: startWorkingOptions[0].key,
            magnetHospitalImportance: magnetHospitalImportanceOptions.find(
              (el) => el.key.includes('not_important'),
            ).key,
            teachingFacilityImportance: teachingFacilityImportanceOptions.find(
              (el) => el.key.includes('not_important'),
            ).key,
            traumaCenterImportance: traumaCenterImportanceOptions.find((el) =>
              el.key.includes('not_important'),
            ).key,
          });
        } else {
          showNotification('Error: preference options are empty', 'warning');
        }
      })
      .catch((e) => {
        showNotification(
          `Error: could not fetch preference options. ${e.message}`,
          'warning',
        );
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { showNotification } = this.props;
    this.setState({ loadingData: true, jobMatches: [] });

    apiDataProvider(GET_LIST, API_RESOURCES.JOB_MATCHES, {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'name' },
      filters: {
        best_assignments: this.state.bestAssignments.toString(),
        main_interest: this.state.mainInterest,
        shift: this.state.shift,
        start_working_period: this.state.startWorking,
        specific_start_date: this.state.specificStartDate,
        min_net_pay_in_cents: this.state.minNetPayInCents,
        max_net_pay_in_cents: this.state.maxNetPayInCents,
        working_places: this.state.workingPlaces.toString(),
        specialities: this.state.specialties.toString(),
        care_settings: this.state.careSettings.toString(),
        travel_goals: this.state.travelGoals.toString(),
        user_preferred_locations: this.state.userPreferredLocations.toString(),
        magnet_hospital_importance: this.state.magnetHospitalImportance.toString(),
        teaching_facility_importance: this.state.teachingFacilityImportance.toString(),
        trauma_center_importance: this.state.traumaCenterImportance.toString(),
      },
    })
      .then((response) => {
        this.setState({ loadingData: false, jobMatches: response.data });
      })
      .catch((e) => {
        this.setState({ loadingData: false, jobMatches: [] });
        showNotification(e.message, 'warning');
      });
  };

  handleMinNetPayInCents = (e) => {
    this.setState({ minNetPayInCents: e.target.value });
  };

  handleBestAssignments = (e) => {
    this.setState({ bestAssignments: [...e.target.value] });
  };

  handleMainInterest = (e) => {
    this.setState({ mainInterest: e.target.value });
  };

  handleShift = (e) => {
    this.setState({ shift: e.target.value });
  };

  handleStartWorking = (e) => {
    if (
      e.target.value ===
      JOB_MATCH_PREFERENCES.START_WORKING_PERIOD_SPECIFIC_DATE
    ) {
      this.setState({ specificStartDateDisabled: false });
    } else {
      this.setState({ specificStartDateDisabled: true });
    }
    this.setState({ startWorking: e.target.value });
  };

  handleDateChange = (e) => {
    this.setState({ specificStartDate: e.target.value });
  };

  handleWorkingPlaces = (e) => {
    this.setState({ workingPlaces: [...e.target.value] });
  };

  handleSpecialties = (e) => {
    this.setState({ specialties: [...e.target.value] });
  };

  handleCareSettings = (e) => {
    if (e.target.value.length <= 3) {
      this.setState({ careSettings: [...e.target.value] });
    }
  };

  handleTravelGoals = (e) => {
    this.setState({ travelGoals: [...e.target.value] });
  };

  handleAddingCityAndState = (stateAndCity) => {
    const userPreferredLocations = this.state.userPreferredLocations;
    if (!userPreferredLocations.includes(stateAndCity))
      userPreferredLocations.push(stateAndCity);
    this.setState({ userPreferredLocations });
  };

  handleDeleteCityAndState = (stateAndCity) => {
    const userPreferredLocations = this.state.userPreferredLocations.filter(
      (el) => el !== stateAndCity,
    );
    this.setState({ userPreferredLocations });
  };

  handleMagnetHospitalImportance = (e) => {
    this.setState({ magnetHospitalImportance: e.target.value });
  };

  handleTeachingFacilityImportance = (e) => {
    this.setState({ teachingFacilityImportance: e.target.value });
  };

  handleTraumaCenterImportance = (e) => {
    this.setState({ traumaCenterImportance: e.target.value });
  };

  render() {
    if (this.state.preferenceOptions.length > 0) {
      return (
        <div>
          <JobMatchesForm
            handleSubmit={this.handleSubmit}
            minNePayInCents={this.state.minNetPayInCents}
            handleMinNetPayInCents={this.handleMinNetPayInCents}
            bestAssignments={this.state.bestAssignments}
            handleBestAssignments={this.handleBestAssignments}
            bestAssignmentOptions={this.state.bestAssignmentOptions}
            mainInterest={this.state.mainInterest}
            handleMainInterest={this.handleMainInterest}
            mainInterestOptions={this.state.mainInterestOptions}
            shift={this.state.shift}
            handleShift={this.handleShift}
            shiftOptions={this.state.shiftOptions}
            startWorking={this.state.startWorking}
            handleStartWorking={this.handleStartWorking}
            startWorkingOptions={this.state.startWorkingOptions}
            specificStartDate={this.state.specificStartDate}
            specificStartDateDisabled={this.state.specificStartDateDisabled}
            handleDateChange={this.handleDateChange}
            workingPlaces={this.state.workingPlaces}
            handleWorkingPlaces={this.handleWorkingPlaces}
            workingPlaceOptions={this.state.workingPlaceOptions}
            specialties={this.state.specialties}
            handleSpecialties={this.handleSpecialties}
            specialtiesOptions={this.state.specialtiesOptions}
            careSettings={this.state.careSettings}
            handleCareSettings={this.handleCareSettings}
            careSettingsOptions={this.state.careSettingsOptions}
            travelGoals={this.state.travelGoals}
            handleTravelGoals={this.handleTravelGoals}
            travelGoalOptions={this.state.travelGoalOptions}
            handleAddingCityAndState={this.handleAddingCityAndState}
            handleDeleteCityAndState={this.handleDeleteCityAndState}
            userPreferredLocations={this.state.userPreferredLocations}
            magnetHospitalImportance={this.state.magnetHospitalImportance}
            handleMagnetHospitalImportance={this.handleMagnetHospitalImportance}
            magnetHospitalImportanceOptions={
              this.state.magnetHospitalImportanceOptions
            }
            teachingFacilityImportance={this.state.teachingFacilityImportance}
            handleTeachingFacilityImportance={
              this.handleTeachingFacilityImportance
            }
            teachingFacilityImportanceOptions={
              this.state.teachingFacilityImportanceOptions
            }
            traumaCenterImportance={this.state.traumaCenterImportance}
            handleTraumaCenterImportance={this.handleTraumaCenterImportance}
            traumaCenterImportanceOptions={
              this.state.traumaCenterImportanceOptions
            }
          />
          {this.state.loadingData && <Loading />}
          <JobMatches
            specialties={this.state.specialtiesElements}
            jobMatches={this.state.jobMatches}
            bestAssignments={this.state.bestAssignments}
          />
        </div>
      );
    }

    return <Loading />;
  }
}

export default connect(
  null,
  { showNotification },
)(FindJobMatchesComponent);
