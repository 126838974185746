import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import {
  Chip,
  FormControl,
  Paper,
  TextField,
  withStyles,
} from '@material-ui/core';

import jsonData from './us_latlng.json';
const stateCities = JSON.parse(JSON.stringify(jsonData));

const autoSuggestStyles = {
  autosuggest: {
    display: 'flex',
  },
  formControl: {
    margin: 8,
  },
  formControlOptions: {
    margin: 8,
    display: 'inline-block',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  suggestionsList: {
    listStyle: 'none',
    padding: 0,
  },
  suggestion: {
    padding: '12px 16px',
    cursor: 'pointer',
  },
};

const renderInputComponent = (inputProps) => (
  <TextField inputProps={inputProps} />
);

const getStateSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : Object.keys(stateCities).filter(
        (state) => state.toLowerCase().slice(0, inputLength) === inputValue,
      );
};

const getCitySuggestions = (value, state) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  const cities = Object.keys(stateCities[state].cities);

  return inputLength === 0
    ? []
    : cities.filter(
        (city) => city.toLowerCase().slice(0, inputLength) === inputValue,
      );
};

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

class StateCityAutoSuggest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: '',
      states: [],
      citiesDisabled: true,
      city: '',
      cities: [],
      lat: 0,
      lng: 0,
    };
  }

  onStateChange = (event, { newValue }) => {
    this.setState({
      state: newValue,
    });
  };

  onCityChange = (event, { newValue }) => {
    this.setState({
      city: newValue,
    });
  };

  onStateSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      states: getStateSuggestions(value),
    });
  };

  onStateSuggestionsClearRequested = () => {
    this.setState({
      states: [],
    });
  };

  onCitySuggestionsFetchRequested = ({ value }) => {
    this.setState({
      cities: getCitySuggestions(value, this.state.state),
    });
  };

  onCitySuggestionsClearRequested = () => {
    this.setState({
      cities: [],
    });
  };

  onStateSuggestionSelected = (event, { suggestion }) => {
    const cities = Object.keys(stateCities[suggestion].cities);
    this.setState({
      citiesEnabled: true,
      cities,
      city: '',
      lat: 0,
      lng: 0,
      citiesDisabled: false,
    });
  };

  render() {
    const { classes, ...props } = this.props;
    const { state, states, city, cities } = this.state;

    const inputStateProps = {
      placeholder: 'Type a US state',
      value: state,
      onChange: this.onStateChange,
    };

    const inputCityProps = {
      placeholder: 'Type a city',
      value: city,
      onChange: this.onCityChange,
      disabled: this.state.citiesDisabled,
    };

    return (
      <div>
        <FormControl className={classes.formControl}>
          <Autosuggest
            suggestions={states}
            onSuggestionsFetchRequested={this.onStateSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onStateSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={this.onStateSuggestionSelected}
            renderInputComponent={renderInputComponent}
            inputProps={inputStateProps}
            theme={{
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
            }}
            renderSuggestionsContainer={(options) => (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            )}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Autosuggest
            suggestions={cities}
            onSuggestionsFetchRequested={this.onCitySuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onCitySuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(e, { suggestion }) => {
              const cityLatLon =
                stateCities[this.state.state].cities[suggestion];
              props.handleAddingCityAndState(
                `${this.state.state}/${suggestion}/${cityLatLon.lat}/${cityLatLon.lng}`,
              );
            }}
            renderInputComponent={renderInputComponent}
            inputProps={inputCityProps}
            theme={{
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
            }}
            renderSuggestionsContainer={(options) => (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            )}
          />
        </FormControl>
        {props.userPreferredLocations.length > 0 && (
          <FormControl className={classes.formControlOptions}>
            {props.userPreferredLocations.map((stateAndCity) => (
              <Chip
                label={stateAndCity
                  .split('/')
                  .slice(0, 2)
                  .join('/')}
                key={stateAndCity}
                onDelete={() => props.handleDeleteCityAndState(stateAndCity)}
              />
            ))}
          </FormControl>
        )}
      </div>
    );
  }
}

export default withStyles(autoSuggestStyles)(StateCityAutoSuggest);
