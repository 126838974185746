import React from 'react';
import moment from 'moment';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';

const jobMatchesStyles = {
  tableCell: {
    padding: 5,
  },
  tableCellWebPage: {
    padding: 5,
    width: 50,
  },
};

const JobMatches = ({ classes, ...props }) =>
  props.jobMatches.length > 0 && (
    <Paper>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>Name</TableCell>
            <TableCell className={classes.tableCell}>Specialty</TableCell>
            <TableCell className={classes.tableCell}>State/City</TableCell>
            {!props.bestAssignments.includes('best_assignment_high_paying') && (
              <TableCell className={classes.tableCell}>
                Matching Value
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              Payment in cents
            </TableCell>
            <TableCell className={classes.tableCell}>Mobile Phone</TableCell>
            <TableCell className={classes.tableCellWebPage}>Web Page</TableCell>
            <TableCell className={classes.tableCell}>Dates</TableCell>
            <TableCell className={classes.tableCell}>
              <div>Fastest Turnaround</div>/<div>Great Location</div>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <div>Magnet Hospital</div>/<div>Teaching Facility</div>/
              <div>Trauma Center</div>
            </TableCell>
            <TableCell className={classes.tableCell}>Job Preferences</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.jobMatches.map((jobMatch) => (
            <TableRow key={jobMatch.id}>
              <TableCell className={classes.tableCell} align="right">
                {jobMatch.name}
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                {
                  props.specialties.find((el) =>
                    el.id.includes(jobMatch.specialty_id),
                  ).displayedName
                }
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                {jobMatch.state}/{jobMatch.city}
              </TableCell>
              {!props.bestAssignments.includes(
                'best_assignment_high_paying',
              ) && (
                <TableCell className={classes.tableCell}>
                  {Math.round(jobMatch.matchingValue * 100) / 100}
                </TableCell>
              )}
              <TableCell className={classes.tableCell} align="right">
                <div>Weekly net: {jobMatch.weekly_net_payment_in_cents}</div>
                <div>
                  Weekly gross: {jobMatch.weekly_gross_payment_in_cents}
                </div>
                <div>
                  Travel Reimbu.: {jobMatch.travel_reimbursement_in_cents}
                </div>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                {jobMatch.mobile_phone}
              </TableCell>
              <TableCell className={classes.tableCellWebPage} align="right">
                {jobMatch.web_page}
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <div>
                  Start date: {moment(jobMatch.start_date).format('YYYY-MM-DD')}
                </div>
                <div>
                  End date:{' '}
                  {jobMatch.end_date
                    ? moment(jobMatch.end_date).format('YYYY-MM-DD')
                    : 'null'}
                </div>
                <div>
                  Created at: {moment(jobMatch.created_at).format('YYYY-MM-DD')}
                </div>
                <div>
                  Updated at: {moment(jobMatch.updated_at).format('YYYY-MM-DD')}
                </div>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Checkbox
                  color="default"
                  disabled
                  checked={jobMatch.is_fastest_turnaround}
                />
                /
                <Checkbox
                  color="default"
                  disabled
                  checked={jobMatch.is_great_location}
                />
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Checkbox
                  color="default"
                  disabled
                  checked={jobMatch.is_magnet_hospital}
                />
                /
                <Checkbox
                  color="default"
                  disabled
                  checked={jobMatch.is_teaching_facility}
                />
                /
                <Checkbox
                  color="default"
                  disabled
                  checked={jobMatch.is_trauma_center}
                />
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                {jobMatch.job_preference_names.sort().map((el, idx) => (
                  <div key={`${idx}.${el}`}>{el}</div>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );

export default withStyles(jobMatchesStyles)(JobMatches);
