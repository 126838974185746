import React from 'react';
import { HydraAdmin } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import {
  dataProvider as baseDataProvider,
  fetchHydra as baseFetchHydra,
} from '@api-platform/admin';
import { Redirect, Route } from 'react-router-dom';
import { ResourceGuesser } from '@api-platform/admin';
import { Resource } from 'react-admin';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleIcon from '@material-ui/icons/People';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';

import authProvider from './authProvider';
import apiAdditionalFunctionality from './decorators/apiAdditionalFunctionality';
import mediaObjects from './components/MediaObject';
import locations from './components/Location';
import facilities from './components/Facility';
import users from './components/User';
import jobMatches from './components/JobMatch';
import { API_URL, ROLE_ADMIN, ROLE_IMAGES_MANAGER } from './constants';

const fetchHeaders = {
  Authorization: `Bearer ${window.localStorage.getItem('token')}`,
};
const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
  });
const apiDocumentationParser = (API_URL) =>
  parseHydraDocumentation(API_URL, {
    headers: new Headers(fetchHeaders),
  }).then(
    ({ api }) => ({ api }),
    (result) => {
      const { api, status } = result;

      if (status === 401) {
        return Promise.resolve({
          api,
          status,
          customRoutes: [
            <Route path="/" render={() => <Redirect to="/login" />} />,
          ],
        });
      }

      return Promise.reject(result);
    },
  );
const dataProvider = baseDataProvider(
  API_URL,
  fetchHydra,
  apiDocumentationParser,
);
export const apiDataProvider = apiAdditionalFunctionality(dataProvider);

export default () => (
  <HydraAdmin
    apiDocumentationParser={apiDocumentationParser}
    dataProvider={apiDataProvider}
    authProvider={authProvider}
    API_URL={API_URL}
  >
    {(permissions) => [
      permissions.includes(ROLE_ADMIN)
        ? [
            <Resource name="users" {...users} icon={PeopleIcon} />,
            <Resource name="job_matches" {...jobMatches} icon={WorkIcon} />,
            <Resource
              name="media_objects"
              {...mediaObjects}
              icon={ImageIcon}
            />,
            <Resource
              name="locations"
              {...locations}
              icon={LocationSearchingIcon}
            />,
            <Resource name="location_images" />,
            <Resource
              name="facilities"
              {...facilities}
              icon={AccountBalanceIcon}
            />,
            <Resource name="facility_images" />,
            <ResourceGuesser name="external_response_logs" />,
            <ResourceGuesser name="feedback" />,
          ]
        : null,
      permissions.includes(ROLE_IMAGES_MANAGER)
        ? [
            <Resource name="media_objects" />,
            <Resource
              name="locations"
              {...locations}
              icon={LocationSearchingIcon}
            />,
            <Resource name="location_images" />,
            <Resource
              name="facilities"
              {...facilities}
              icon={AccountBalanceIcon}
            />,
            <Resource name="facility_images" />,
          ]
        : null,
    ]}
  </HydraAdmin>
);
