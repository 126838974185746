import { GET_LIST } from 'react-admin';

import { API_URL } from './../../constants';

const jobMatchesHandler = async (type, resource, params, requestHandler) => {
  switch (type) {
    case GET_LIST:
      const { filters } = params;
      const queryString = Object.keys(filters)
        .map((key) => key + '=' + filters[key])
        .join('&');

      if (filters) {
        return fetch(
          `${API_URL}/job_matches/get_job_matches_by_preferences?${queryString}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            },
          },
        ).then(async (response) => {
          const responseJson = await response.json();
          if (response.ok) {
            if (responseJson.length > 0) {
              return {
                data: responseJson,
                total: responseJson.length,
              };
            }
            throw new Error('No records found');
          }

          throw new Error(responseJson.error);
        });
      }

      return requestHandler(type, resource, params);
    default:
      return requestHandler(type, resource, params);
  }
};

export default jobMatchesHandler;
