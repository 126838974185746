import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  TextField,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';

const UserListActions = () => <div></div>;

const RolesField = ({ record }) => (

  <ul>
    {record.roles != null &&
      record.roles.map((item) => (
        <Chip label={item} key={item}/>
      ))
    }
  </ul>
);

const UserList = ({ classes, ...props }) => (
  <List {...props} actions={<UserListActions />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="firstName" />
      <TextField source="middleName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <BooleanField source="isConfirmed" />
      <RolesField source="roles" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <DateField source="dateOfBirth" />
      <TextField source="street" />
      <TextField source="city" />
      <TextField source="zipCode" />
      <TextField source="socialSecurityNumber" />
      <BooleanField source="hasSuspendedLicence" />
      <BooleanField source="hasTerminated" />
      <TextField source="hasEmployedUnderOtherName" />
      <BooleanField source="isUsCitizen" />
      <BooleanField source="canShowProof" />
      <DateField source="specificStartDate" />
      <TextField source="yearsOfExperience" />
    </Datagrid>
  </List>
);

export default UserList;
