export const API_URL = process.env.REACT_APP_API_ENTRYPOINT;
export const AUTHENTICATION_TOKEN_URL = `${API_URL}/authentication_token`;
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_IMAGES_MANAGER = 'ROLE_IMAGES_MANAGER';

export const API_RESOURCES = {
  PREFERENCE_OPTIONS: 'preference_options',
  SPECIALTIES: 'specialties',
  JOB_MATCHES: 'job_matches',
  MEDIA_OBJECTS: 'media_objects',
  LOCATIONS: 'locations',
  FACILITIES: 'facilities',
  USERS: 'users',
  FACILITY_IMAGES: 'facility_images',
  LOCATION_IMAGES: 'location_images',
};

export const JOB_MATCH_PREFERENCES = {
  MAIN_INTEREST: 'main_interest',
  BEST_ASSIGNMENT: 'best_assignment',
  CARE_SETTINGS: 'care_settings',
  SPECIALTIES: 'specialties',
  MAGNET_HOSPITAL: 'magnet_hospital',
  SHIFT: 'shift',
  START_WORKING: 'start_working',
  TEACHING_FACILITY: 'teaching_facility',
  TRAUMA_CENTER: 'trauma_center',
  TRAVEL_GOAL: 'travel_goal',
  WORKING_PLACE: 'working_place',
  START_WORKING_PERIOD_SPECIFIC_DATE: 'start_working_period_specific_date',
  MAGNET_HOSPITAL_IMPORTANCE: 'magnet_hospital_importance',
  TEACHING_FACILITY_IMPORTANCE: 'teaching_facility_importance',
  TRAUMA_CENTER_IMPORTANCE: 'trauma_center_importance',
};
