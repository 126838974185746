import React from 'react';
import {
  BooleanInput,
  DateField,
  DateInput,
  DisabledInput,
  Edit,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TextField,
  BooleanField,
  NumberField,
} from 'react-admin';

const UserEditActions = () => <div></div>;

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const UserEdit = (props) => (
  <Edit
    {...props}
    undoable={false}
    title="User edit"
    actions={<UserEditActions />}
  >
    <SimpleForm toolbar={<UserEditToolbar />}>
      <DisabledInput source="id" />
      <TextInput source="mobilePhone" defaultValue={null} />
      <TextInput source="firstName" defaultValue={null} />
      <TextInput source="middleName" defaultValue={null} />
      <TextInput source="lastName" defaultValue={null} />
      <BooleanInput source="isConfirmed" />
      <TextInput source="email" type="email" />
      {/* <ArrayInput source="roles">
                        <SimpleFormIterator>
                            <DateInput source="date" />
                            <TextInput source="url" />
                        </SimpleFormIterator>
                    </ArrayInput> */}
      <TextField source="profilePhotoPath" defaultValue={null} />
      {/*<DisabledInput source="emailVerificationCode" defaultValue={null} />*/}
      <DateField source="confirmationSentAt" showTime defaultValue={null} />
      <BooleanField source="isTemporaryPasswordSet" />
      <NumberField source="minNetPayInCents" defaultValue={null} />
      <NumberField source="maxNetPayInCents" defaultValue={null} />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <DateField source="dateOfBirth" showTime defaultValue={null} />
      <TextField source="street" defaultValue={null} />
      <TextField source="city" defaultValue={null} />
      <TextField source="zipCode" defaultValue={null} />
      <TextField source="socialSecurityNumber" defaultValue={null} />
      <BooleanField source="hasSuspendedLicence" />
      <BooleanField source="hasTerminated" />
      <TextField source="hasEmployedUnderOtherName" defaultValue={null} />
      <BooleanField source="isUsCitizen" />
      <BooleanField source="canShowProof" />
      <DateField source="specificStartDate" showTime defaultValue={null} />
      <TextField source="yearsOfExperience" defaultValue={null} />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
