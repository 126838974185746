import React from 'react';
import PropTypes from 'prop-types';

const PhotoCounterField = ({ source, record = {} }) => {
  if (record[source] && record[source] === 1) {
    return <span>{record[source]} photo</span>;
  } else if (record[source] && record[source] > 1) {
    return <span>{record[source]} photos</span>;
  }

  return <span>no photo</span>;
};

PhotoCounterField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default PhotoCounterField;
