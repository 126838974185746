import React from 'react';
import { Button } from 'react-admin';

const CancelButton = ({
  onCancel,
  basePath,
  className,
  classes,
  saving,
  invalid,
  variant,
  translate,
  handleSubmit,
  handleSubmitWithRedirect,
  submitOnEnter,
  record,
  redirect,
  resource,
  locale,
  showNotification,
  undoable,
  pristine,
  ...rest
}) => <Button {...rest} onClick={onCancel} label="Cancel" />;

export default CancelButton;
