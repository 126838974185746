import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { ImageField, TextField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  image: {
    width: 65,
    height: 65,
    display: 'flex',
    '& img': {
      maxWidth: 35,
      maxHeight: 35,
      margin: 'auto',
      borderRadius: 5,
    },
  },
};

const MediaObjectList = withStyles(styles)(({ classes, ...props }) => (
  <ListGuesser {...props}>
    <TextField source="@id" label="Id" />
    <ImageField source="contentUrl" label="Preview" className={classes.image} />
  </ListGuesser>
));

export default MediaObjectList;
