import { UPDATE } from 'react-admin';

import { API_URL } from './../../constants';

const usersHandler = async (type, resource, params, requestHandler) => {
  switch (type) {
    case UPDATE:
      const { data, previousData } = params;
      const changes = {
        ...(data.mobilePhone !== previousData.mobilePhone
          ? { mobilePhone: data.mobilePhone }
          : {}),
        ...(data.firstName !== previousData.firstName
          ? { firstName: data.firstName }
          : {}),
        ...(data.middleName !== previousData.middleName
          ? { middleName: data.middleName }
          : {}),
        ...(data.lastName !== previousData.lastName
          ? { lastName: data.lastName }
          : {}),
        ...(data.isConfirmed !== previousData.isConfirmed
          ? { isConfirmed: data.isConfirmed }
          : {}),
        ...(data.email !== previousData.email ? { email: data.email } : {}),
        ...(data.username !== previousData.username
          ? { username: data.username }
          : {}),
        ...(data.profilePhotoPath !== previousData.profilePhotoPath
          ? { profilePhotoPath: data.profilePhotoPath }
          : {}),
        ...(data.isTemporaryPasswordSet !== previousData.isTemporaryPasswordSet
          ? { isTemporaryPasswordSet: data.isTemporaryPasswordSet }
          : {}),
        ...(data.minNetPayInCents !== previousData.minNetPayInCents
          ? { minNetPayInCents: data.minNetPayInCents }
          : {}),
        ...(data.maxNetPayInCents !== previousData.maxNetPayInCents
          ? { maxNetPayInCents: data.maxNetPayInCents }
          : {}),
        ...(data.dateOfBirth !== previousData.dateOfBirth
          ? { dateOfBirth: data.dateOfBirth }
          : {}),
        ...(data.street !== previousData.street ? { street: data.street } : {}),
        ...(data.city !== previousData.city ? { city: data.city } : {}),
        ...(data.zipCode !== previousData.zipCode
          ? { zipCode: data.zipCode }
          : {}),
        ...(data.socialSecurityNumber !== previousData.socialSecurityNumber
          ? { socialSecurityNumber: data.socialSecurityNumber }
          : {}),
        ...(data.hasSuspendedLicence !== previousData.hasSuspendedLicence
          ? { hasSuspendedLicence: data.hasSuspendedLicence }
          : {}),
        ...(data.hasTerminated !== previousData.hasTerminated
          ? { hasTerminated: data.hasTerminated }
          : {}),
        ...(data.hasEmployedUnderOtherName !==
        previousData.hasEmployedUnderOtherName
          ? { hasEmployedUnderOtherName: data.hasEmployedUnderOtherName }
          : {}),
        ...(data.isUsCitizen !== previousData.isUsCitizen
          ? { isUsCitizen: data.isUsCitizen }
          : {}),
        ...(data.canShowProof !== previousData.canShowProof
          ? { canShowProof: data.canShowProof }
          : {}),
        ...(data.specificStartDate !== previousData.specificStartDate
          ? { specificStartDate: data.specificStartDate }
          : {}),
        ...(data.yearsOfExperience !== previousData.yearsOfExperience
          ? { yearsOfExperience: data.yearsOfExperience }
          : {}),
      };
      const body = JSON.stringify(changes);

      return fetch(`${API_URL}${params.id}`, {
        body,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          'Content-Type': 'application/ld+json',
        },
      }).then((response) => {
        if (response.status == 400) {
          return Promise.resolve(response.json()).then(function(data) {
            let error = data.error != null ? data.error : 'An error occurred';
            return Promise.reject(error);
          });
        }
        return Promise.resolve(response.json()).then((data) => ({
          data: {
            ...data,
            id: data['@id'],
          },
        }));
      });
    default:
      return requestHandler(type, resource, params);
  }
};

export default usersHandler;
