import React from 'react';
import {
  Create,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  Toolbar,
} from 'react-admin';

const MediaObjectCreate = (props) => (
  <Create {...props} title="Media Object upload">
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton label="Upload" />
        </Toolbar>
      }
    >
      <ImageInput
        source="image"
        label="Media Objects"
        accept="image/jpeg"
        multiple={false}
        placeholder={<p>Drop your image here (Or click to select file)</p>}
      >
        <ImageField source="src" label="Drop image" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default MediaObjectCreate;
