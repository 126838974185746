import {
  facilitiesHandler,
  jobMatchesHandler,
  locationsHandler,
  mediaObjectsHandler,
  usersHandler,
} from './handlers';
import { API_RESOURCES } from './../constants';

const apiAdditionalFunctionality = (requestHandler) => async (
  type,
  resource,
  params,
) => {
  switch (resource) {
    case API_RESOURCES.MEDIA_OBJECTS:
      return mediaObjectsHandler(type, resource, params, requestHandler);
    case API_RESOURCES.LOCATIONS:
      return locationsHandler(type, resource, params, requestHandler);
    case API_RESOURCES.FACILITIES:
      return facilitiesHandler(type, resource, params, requestHandler);
    case API_RESOURCES.USERS:
      return usersHandler(type, resource, params, requestHandler);
    case API_RESOURCES.JOB_MATCHES:
      return jobMatchesHandler(type, resource, params, requestHandler);
    default:
      return requestHandler(type, resource, params);
  }
};

export default apiAdditionalFunctionality;
