import React, { Component, Fragment } from 'react';
import { Datagrid, List, Loading, TextField } from 'react-admin';
import { Route } from 'react-router';
import { Drawer, withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import LocationFilter from './LocationFilter';
import ImageSourceField from './../ImageSourceField';
import PhotoCounterField from './../PhotoCounterField';
import LocationEdit from './LocationEdit';

const drawerStyles = {
  drawerContent: {
    width: 500,
  },
};

const LocationListActions = () => <div></div>;

class LocationListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingRecords: false };
  }

  handleClose = () => {
    this.props.push('/locations');
  };

  loadingRecords = () => {
    this.setState({
      loadingRecords: true,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.isLoading &&
      this.props.isLoading &&
      this.state.loadingRecords
    ) {
      this.setState({
        loadingRecords: false,
      });
    }
  }

  render() {
    const { push, classes, ...props } = this.props;

    return (
      <Fragment>
        <List
          {...props}
          title="Locations"
          actions={<LocationListActions />}
          bulkActionButtons={false}
          filters={<LocationFilter loadingRecords={this.loadingRecords} />}
        >
          {this.state.loadingRecords ? (
            <Loading />
          ) : (
            <Datagrid rowClick="edit">
              <ImageSourceField />
              <TextField source="city" label="City" />
              <TextField source="state" label="State" />
              <PhotoCounterField source="photoCounter" label="Photo" />
            </Datagrid>
          )}
        </List>
        <Route path="/locations/:id">
          {({ match }) => {
            if (!match) {
              return <div />;
            }
            const isMatch =
              match && match.params && match.params.id !== 'create';

            return (
              <Drawer open={isMatch} anchor="right" onClose={this.handleClose}>
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch ? (
                  <LocationEdit
                    className={classes.drawerContent}
                    id={decodeURIComponent(match.params.id)}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.admin.loading > 0,
});

export default compose(
  connect(
    mapStateToProps,
    { push },
  ),
  withStyles(drawerStyles),
)(LocationListComponent);
