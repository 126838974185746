import React from 'react';
import { ImageField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import placeholder from './placeholder.png';

const styles = {
  image: {
    width: 65,
    height: 65,
    display: 'flex',
    '& img': {
      maxWidth: 35,
      maxHeight: 35,
      margin: 'auto',
      borderRadius: 5,
    },
  },
};

const ImageSourceField = withStyles(styles)(({ classes, record, ...rest }) =>
  record && record.imageUrl ? (
    <ImageField
      source="imageUrl"
      record={record}
      {...rest}
      className={classes.image}
    />
  ) : (
    <div className={classes.image}>
      <img src={placeholder} alt="Placeholder" />
    </div>
  ),
);

export default ImageSourceField;
