import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from 'react-admin';
import decodeJwt from 'jwt-decode';

import { AUTHENTICATION_TOKEN_URL } from './constants';

export default (type, params) => {
  switch (type) {
    case AUTH_LOGIN:
      const { username, password } = params;
      const request = new Request(AUTHENTICATION_TOKEN_URL, {
        method: 'POST',
        body: JSON.stringify({ username: username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });

      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300)
            throw new Error(response.statusText);

          return response.json();
        })
        .then(({ token }) => {
          const decodedToken = decodeJwt(token);
          localStorage.setItem('token', token);
          localStorage.setItem('roles', decodedToken.roles);
          window.location.replace('/');
        });

    case AUTH_LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('roles');
      break;

    case AUTH_ERROR:
      if (401 === params.status || 403 === params.status) {
        localStorage.removeItem('token');
        localStorage.removeItem('roles');

        return Promise.reject();
      }
      break;

    case AUTH_CHECK:
      return localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject();

    case AUTH_GET_PERMISSIONS:
      const roles = localStorage.getItem('roles');
      return roles ? Promise.resolve(roles) : Promise.reject();

    default:
      return Promise.resolve();
  }
};
