import React from 'react';
import {
  Edit,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar,
} from 'react-admin';

import CancelButton from './../CancelButton';

const LocationEditToolbar = ({ onCancel, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <CancelButton onCancel={onCancel} />
  </Toolbar>
);

const LocationEditActions = () => <div></div>;

const LocationEdit = ({ onCancel, ...props }) => (
  <Edit {...props} undoable={false} title=" " actions={<LocationEditActions />}>
    <SimpleForm toolbar={<LocationEditToolbar onCancel={onCancel} />}>
      <TextField source="state" />
      <TextField source="city" />
      <ImageField source="imageUrl" label="Preview" />
      <ImageInput
        source="image"
        label="New Image"
        accept="image/jpeg"
        multiple={false}
        placeholder={<p>Upload image for this location</p>}
      >
        <ImageField source="src" label="Drop image" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export default LocationEdit;
